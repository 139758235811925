import React from "react";
import "./HeroSection.css"; // Import the CSS file for styling
import { Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";

const HeroSection = ({ onExploreClick }: { onExploreClick: () => void }) => {
  // const nav = useNavigate();
  return (
    <div className="hero-section">
      {/* Background Image */}
      <div className="hero-image"></div>

      {/* Text Overlay */}
      <div className="hero-text">
        <h1>Welcome Banvego Health Solution Group</h1>
        <p>
          Providing quality Environmental, Educational, and Medical Delivery
          services in Central Florida.
        </p>

        <p>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={onExploreClick}
          >
            Explore Our Services
          </Button>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
