import React from "react";
import { Container, Typography, Avatar, Box } from "@mui/material";
import "./AboutUs.css"; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <Container className="about-us-container" sx={{ padding: 2 }}>
      <Box>
        {/* <Paper elevation={3} className="about-us-paper"> */}
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color="secondary"
          sx={{ textAlign: "center", m: 5 }}
        >
          About Us
        </Typography>
        <Typography variant="body1">
          BANVEGO Health Solution Group Incorporation, is a family company that
          provides professional health care solutions services, supporting the
          effective administration of health care programs and funding to
          support health care improvement resourcefulness and also dedicated to
          providing Top-NoTch , house cleaning, Effective Health/ administrative
          medical personnel (supply of labor), home, offices, health Care
          facility and it's environment, educational services short term courses
          competency training, we Render a sort of responsibility of recreation
          healthcarel program for physical and developmentally disable disable
          kids/ children, Ageing people who lives at their homes, medical
          delivery services which also includes (specimen and samples)
          (logistics) services. We strive to ensure a healthy and clean
          environment for our clients.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 5,
          }}
        >
          <Avatar
            alt="CEO"
            //src={require("../../images/veronica.jpg")}
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
            Tutu Ogoh <br />
            (CEO & Founder)
          </Typography>
        </Box>
      </Box>
      {/* </Paper> */}
    </Container>
  );
};

export default AboutUs;
