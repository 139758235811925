import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import "./ScheduleConsultation.css"; // Import the CSS file for styling
import { utils } from "../../utils";

interface FormData {
  name: string;
  email: string;
  date: string;
  time: string;
  message: string;
}

const ScheduleConsultation: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    date: getDefaultDate(),
    time: getDefaultTime(),
    message: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  function getDefaultDate(): string {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const year = tomorrow.getFullYear();
    let month = tomorrow.getMonth() + 1;
    let day = tomorrow.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
  }

  function getDefaultTime(): string {
    const now = new Date();
    let hours = now.getHours();

    let minutes = now.getMinutes();

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission (you can add your logic here)
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container className="schedule-consultation-container">
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        color="secondary"
        sx={{ textAlign: "center", m: 1 }}
      >
        Schedule Consultation
      </Typography>

      <Typography variant="body1" sx={{ textAlign: "center", m: 1 }}>
        You can contact us to schedule a consultation by sending an email to{" "}
        <a href={`mailto:${utils.contactEmail}`}>{utils.contactEmail}</a> or by
        calling us at{" "}
        <a href={`tel:${utils.contactPhone}`}>{utils.contactPhone}</a>.
      </Typography>
    </Container>
  );
};

export default ScheduleConsultation;
